import React from 'react';
import Image from 'next/image';
//import Link from 'next/link';
//import { AuctionSection } from '@/sections/marketplace/AuctionSection';
import Badge_1 from '@/shared/components/Badge_1';
import Badge_2 from '@/shared/components/Badge_2';
import CountdownToDate from './CountdownToDate';
import Link from 'next/link';

const Hero = () => {
    const authorImage =
        'https://thumbs.dreamstime.com/b/default-profile-picture-avatar-photo-placeholder-vector-illustration-default-profile-picture-avatar-photo-placeholder-vector-189495158.jpg';

    //const totalItems = nftsDataWithAhInfo.length;

    //const Collections;
    //const Artists;
    const targetDate_1 = '2024-12-15T23:59:59';
    const targetDate_2 = '2025-04-01T23:59:59'; // scoate secundele

    return (
        <>
            <section className="hero relative py-20 md:pt-32">
                {/*<picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden"> // did not notice its use
                    <Image
                        width={1519}
                        height={773}
                        priority
                        src="/images/gradient.jpg"
                        alt="gradient"
                        className="h-full w-full object-cover"
                    />
                </picture> - Dragos Bacitea:this image does nothing and can be deleted or we can keep a placeholder for other projects*/}

                <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
                    <Image
                        width={1519}
                        height={773}
                        priority
                        className="h-full w-full"
                        src="/images/gradient_dark.jpg"
                        alt="gradient dark"
                    />
                </picture>

                <div className="grid h-full items-center gap-0 lg:grid-cols-12">
                    {/** tittle / info */}
                    <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-10 xl:col-span-5 xl:pl-[20%] xl:pr-[10%]">
                        <div className="mb-10 w-full sm:flex sm:space-x-4">
                            <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <span className="block font-display text-3xl text-[#8DD059]"> 20</span>
                                <span className="block font-display text-sm text-jacarta-500 dark:text-white">
                                    ArtWorks
                                </span>
                            </div>
                            <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <span className="block font-display text-3xl text-[#737EF2]"> 3</span>
                                <span className="block font-display text-sm text-jacarta-500 dark:text-white">
                                    Collections
                                </span>
                            </div>
                            <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <span className="block font-display text-3xl text-[#F35BC7]"> 10</span>
                                <span className="block font-display text-sm text-jacarta-500 dark:text-white">
                                    Artists
                                </span>
                            </div>
                        </div>

                        <div>
                            <div className="mb-6 text-center font-display text-5xl text-jacarta-700 dark:text-white md:text-left lg:text-5xl xl:text-6xl">
                                <h1>
                                    Discover, Collect & Sell
                                    <span className="animate-gradient"> Creative NFTs</span>
                                </h1>
                            </div>
                        </div>

                        <div className="mb-8 text-center text-lg dark:text-jacarta-200 md:text-left">
                            {' '}
                            The leading platform for artists and collectors of both digital and physical artworks,
                            delivering worldwide access and shipping{' '}
                        </div>

                        <div className="flex space-x-4 text-center">
                            <div className="text-center my-1 mb-12">
                                <a
                                    href="https://icoholder.com/en/a-nft-world-1071376"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-36 rounded-full animate-gradient py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                                >
                                    ICO Listing
                                </a>
                            </div>
                            <div className="text-center my-1 mb-12">
                                <a
                                    href="https://artechfusion.gallery/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-36 rounded-full animate-gradient py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                                >
                                    3D Gallery
                                </a>
                            </div>
                        </div>
                        {/* counters */}
                        <div className="mb-10 w-full sm:flex sm:space-x-4">
                            <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <div className="block font-display text-base text-[#8DD059]">
                                    <h1 className="animate-gradient mb-3 text-xl">ATF Open Call</h1>
                                    <div className="mb-5 text-xl">
                                        <CountdownToDate targetDate={targetDate_1} />
                                    </div>
                                    <Link
                                        href="https://forms.office.com/e/H7prnignPz "
                                        className="text-base w-auto rounded-full animate-gradient py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                                    >
                                        ATF Open Call - Romania
                                    </Link>
                                </div>
                            </div>
                            <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <div className="block font-display text-base text-[#8DD059]">
                                    <h1 className="animate-gradient mb-3 text-xl">Art Tech Fusion 2025 START</h1>
                                    <div className="text-xl">
                                        <CountdownToDate targetDate={targetDate_2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** nft preview */}
                    <div className="relative col-span-6 xl:col-span-6 xl:col-start-7">
                        {/*<Image
                            width={150}
                            height={150}
                            src="/images/Badge.png"
                            className="absolute top-0 z-10 -ml-16 animate-spin-slow md:top-[12%]"
                            alt="badge"
                        />*/}
                        <div className="md:flex md:space-x-6 xl:space-x-12">
                            {/** first article */}
                            <div className="mb-6 md:flex md:w-1/2 md:items-center rounded-3xl">
                                <article>
                                    <div className="block overflow-hidden rounded-3xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
                                        <figure className="relative rounded-3xl">
                                            <Image
                                                width={300}
                                                height={330}
                                                src={
                                                    'https://gateway.irys.xyz/3mr9QpAPNWr7gJJ9dbmOU7Y4HWDCnqQTmlnwrDAMXzM'
                                                }
                                                alt="item 1"
                                                className="w-full h-full object-cover rounded-3xl"
                                            />
                                        </figure>
                                        <div className="p-6">
                                            <div className="">
                                                <Image
                                                    width={40}
                                                    height={40}
                                                    src={authorImage}
                                                    alt="avatar"
                                                    className="mr-4 h-10 w-10 rounded-full"
                                                />
                                                <div>
                                                    <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                                        Rețele Verzi - part 1
                                                    </span>

                                                    <div className="flex justify-between pt-6 pb-2">
                                                        <p className="text-2xs text-accent">Anghel</p>
                                                        <div className="text-2xs text-right ml-auto">
                                                            <Badge_1 />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>

                            <div className="space-y-6 md:w-1/2 xl:space-y-12">
                                {/*secound article */}
                                <div className="md:w-3/4 rounded-3xl">
                                    <article>
                                        <div className="block overflow-hidden rounded-3xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
                                            <figure className="relative rounded-3xl">
                                                <Image
                                                    width={300}
                                                    height={330}
                                                    src={
                                                        'https://gateway.irys.xyz/8eTT9oMUEb8tKZJ22av42XI6goorztaV9iIOie8WXD4'
                                                    }
                                                    alt="item 1"
                                                    className="w-full h-full object-cover rounded-3xl"
                                                />
                                            </figure>
                                            <div className="p-6">
                                                <div className="">
                                                    <Image
                                                        width={40}
                                                        height={40}
                                                        src={authorImage}
                                                        alt="avatar"
                                                        className="mr-4 h-10 w-10 rounded-full"
                                                    />
                                                    <div>
                                                        <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                                            Rețele Verzi - part 2
                                                        </span>
                                                        <div className="flex justify-between pt-6 pb-2">
                                                            <p className="text-2xs text-accent">Anghel</p>
                                                            <div className="text-2xs text-right ml-auto">
                                                                <Badge_1 />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="md:w-3/4">
                                    {/* 3rd Article */}
                                    <div className="md:w-3/4 rounded-3xl">
                                        <article>
                                            <div className="block overflow-hidden rounded-3xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
                                                <figure className="relative rounded-3xl">
                                                    <Image
                                                        width={400}
                                                        height={400}
                                                        src={
                                                            'https://gateway.irys.xyz/3-klPgSbIZiHDDLjfB3H4TzEMstPumLRpgLn_rDJFO4'
                                                        }
                                                        alt="item 1"
                                                        className="w-full h-full object-cover rounded-3xl"
                                                    />
                                                </figure>
                                                <div className="p-6">
                                                    <div className="">
                                                        <Image
                                                            width={40}
                                                            height={40}
                                                            src={authorImage}
                                                            alt="avatar"
                                                            className="mr-4 h-10 w-10 rounded-full"
                                                        />
                                                        <div>
                                                            <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                                                Art Tech Fusion
                                                            </span>
                                                            <div className="flex justify-between pt-6 pb-2">
                                                                <p className="text-2xs text-accent">A-NFT-Admin</p>
                                                                <div className="text-2xs text-right ml-auto">
                                                                    <Badge_2 />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Hero;
